@import 'assets/styles/button.scss';

.toggle-btn {
  display: flex;
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  font-size: 14pt;
  outline: none;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s linear;
}