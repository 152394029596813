@import 'assets/styles/themes.scss';

.landing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .parallax {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bgr {
      &-one {
        @include themify($themes) {
          background: themed('bgr_grad_one');
        }
      }

      &-two {
        @include themify($themes) {
          background: themed('bgr_grad_two');
        }
      }
    }

    &-profile {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-about {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-footer {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .footer-text {
        margin-bottom: 13px;
        font-size: 9.5pt;
        font-weight: 500;
        text-align: center;

        @include themify($themes) {
          color: themed('txt_accent');
        }
      }
    }
  }
}
